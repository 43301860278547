'use client';

import { Avatar, Tooltip } from '@/lib/primer';
import type { UserInfo } from '@fidant-io/auth-core-adapter/oidc';

export type Props = Omit<React.ComponentProps<typeof Avatar>, 'src' | 'alt'> & {
  user: UserInfo | null;
  status?: 'authenticated' | 'unauthenticated' | 'loading' | null;
};

export default function AvatarView(props: Props) {
  const { user, status, ...avatarProps } = props;
  const { name = '??', picture = 'https://github.com/octocat.png' } = user ?? {};
  return (
    <>
      <Tooltip text={JSON.stringify(user, null, 2)} wrap direction="sw">
        <Avatar src={picture} alt={`@${name}`} {...avatarProps} />
        &nbsp;{name}
        {status && status !== 'authenticated' && <> ({status})</>}
      </Tooltip>
    </>
  );
}
