'use client';

import { useSession } from '@/auth/client';
import type { Avatar } from '@/lib/primer';

import AvatarView from './AvatarView';

export type Props = Omit<React.ComponentProps<typeof Avatar>, 'src' | 'alt'>;

export default function AvatarSelf(props: Props) {
  const { data: session, status } = useSession();
  const user = session?.user ? { sub: session.user.id, ...session.user } : null;
  return <AvatarView {...props} user={user} status={status} />;
}
