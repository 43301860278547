import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-1168aeecbf/0/cache/next-npm-14.2.13-4a291beaf9-152928801d.zip/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-1168aeecbf/0/cache/next-npm-14.2.13-4a291beaf9-152928801d.zip/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/srv/app/packages/web-ui/src/components/avatar/AvatarSelf.client.tsx");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/components/logo/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SuspenseOrAuthBoundary"] */ "/srv/app/packages/web-ui/src/components/SuspenseOrAuthBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BreadcrumbsItem","Breadcrumbs"] */ "/srv/app/packages/web-ui/src/lib/primer/Breadcrumbs.ts");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderLink","Header","HeaderItem"] */ "/srv/app/packages/web-ui/src/lib/primer/Header.ts");
