import type { AuthAction, PagesOptions } from 'next-auth';

// This corresponds to whereever we put the next-auth route handler:. /auth/[...nextauth]/route.ts
const basePath = `/auth`;

const baseUrlKey = 'AUTH_URL';
const baseUrlString = process.env[baseUrlKey] || `https://[::1]${basePath}`;

// NOTE: the path must end with a slash, otherwise using it as the base URL will drop the path head component.
// NOTE: e.g. "https://foo.example/_a/_b/" is ok.
// but `new URL("foo/bar", "https://foo.example/_a/_b" )` will resolve to "https://foo.example/_a/foo/bar" without "_b".
export const authBaseUrl = baseUrlString.endsWith('/') ? new URL(baseUrlString) : new URL(baseUrlString + '/');

/**
 * [Bad Incorrect Documentation](https://next-auth.js.org/configuration/pages)
 *
 * The following route names are hard-coded in next-auth@4.24.7.
 *
 * They are not actually configurable; only the base of the URL is.
 * (With, I think, the exception of "pages.newUser", which never seems to be used literally.)
 */
const PageNames = {
  // "${base}/${'signin' | 'callback'}/${provider}"
  signIn: 'signin',
  callbacks: 'callback',

  signOut: 'signout',
  error: 'error',
  verifyRequest: 'verify-request',

  // This one, maybe, is configurable?
  newUser: 'new-user',
} as const satisfies PagesOptions & { callbacks: string };

PageNames satisfies Record<Exclude<keyof PagesOptions, 'newUser'> | 'callbacks', AuthAction>;

/**
 * Map of the page names to their absolute paths (not full URL).
 */
export const PagePaths = Object.freeze(
  Object.fromEntries(
    Object.entries(PageNames).map(([key, value]) => [key, new URL(`./${value}`, authBaseUrl).pathname] as const)
  )
) as unknown as Readonly<PagesOptions & { callbacks: string }>;
